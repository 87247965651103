export default {
    MAX_ATTACHMENT_SIZE: 5000000,
 
    // desa
    apiGateway: {
        //NAME: "UE2NETDESAAPIABC001",
        //NAME: "UE1NETDESAAPIFEL001", //DESA
        //NAME: "UE2NETUATAPIFEL001", //TEST
        NAME: "UE2NETPRODAPIFEL001", //PROD
        REGION: "us-east-1",
        //URL_FILE: "https://e8x3y0susc.execute-api.us-east-1.amazonaws.com/DESA/obturlarchivo", //DESA
        //URL_FILE: "https://i4olu3aa44.execute-api.us-east-1.amazonaws.com/TESTF/obturlarchivo", //TEST
        URL_FILE: "https://9be0kffj6f.execute-api.us-east-1.amazonaws.com/felapi/obturlarchivo", //TEST 
        //URL_FILE: "https://facturacion-electronica-ose.auth.us-east-2.amazoncognito.com/oauth2/token?grant_type=client_credentials&client_id=1vocjn9ao2ehni8lqgl7ce43nq&client_secret=fqg2r3eoknulmvm95is6k664nbrlj0o55mtb9nl25uouadp5lrk",
        URL_TIMEOUT: 30, //EN SEGUNDOS
        //URL: "https://e8x3y0susc.execute-api.us-east-1.amazonaws.com/DESA", //DESA
       // URL: "https://zqbilz4sqg.execute-api.us-east-2.amazonaws.com/UAT", //TEST SERFAC
        URL: "https://9oj5qm6rq7.execute-api.us-east-1.amazonaws.com/prod", //PROD SERFAC
        //URL: "https://ldess8hf07.execute-api.us-east-1.amazonaws.com/TEST4", //TESt4
        //URL: "https://bkggnbpyn1.execute-api.us-east-1.amazonaws.com/PROD", //prod
    },
    bucket: "ue2stgprodserart001", //ue2stgdesaserart001  //ue2stgprodserart001
    accessKey: "AKIASLT7MSG2RHZYGL6I",
    secretKey: "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",

    // test
    /*apiGateway: {
        NAME: "UE2NETTESTAPIABC001",
        REGION: "us-east-2",
        URL: "https://nlm0zw9ii9.execute-api.us-east-2.amazonaws.com/costeoabcapi"
    },*/

    // apiGateway: {
    //     NAME: "UE2NETPRODAPIABC001",
    //     REGION: "us-east-2",
    //     URL: "https://ceayqsd783.execute-api.us-east-2.amazonaws.com/costeoabcapi"
    // },

    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-1_C2LVxiepO",  // prod: 1_C2LVxiepO // desa: 2_UvmWSCB4i
        //APP_CLIENT_ID: "3tb8sp3dn9mqcirh6uviljgnd5",  // local
        APP_CLIENT_ID: "7uaigubdf1i8v6mdpt2hvq5oib",  // local  MQ  // dev: 2rio311lk9im8593n2ll0teh5r prd: 7uaigubdf1i8v6mdpt2hvq5oib //prd2 = 83be4p8g981vhfnladml3qeui
        //APP_CLIENT_ID: "3tb8sp3dn9mqcirh6uviljgnd5",  // desa
        //APP_CLIENT_ID: "1uic4625l3keiac2j6hjj1cffh",  // test
        //APP_CLIENT_ID: "3tb8sp3dn9mqcirh6uviljgnd5",  
        IDENTITY_POOL_ID: "us-east-1:2653ce3e-d7e4-4b82-b9cb-a4ac59219ef7aaaaa",
        DOMAIN: "rimac-com-pe.auth.us-east-2.amazoncognito.com",
        //REDIRECT_SIGNIN: "https://d113ku473ook4e.cloudfront.net/login",    //test
        //REDIRECT_SIGNOUT: "https://d113ku473ook4e.cloudfront.net/login",   //test
        //REDIRECT_SIGNIN: "https://d3i3aqsdcqu1qe.cloudfront.net/login",    //desa
        //REDIRECT_SIGNOUT: "https://d3i3aqsdcqu1qe.cloudfront.net/login",   //desa
        REDIRECT_SIGNIN: "http://localhost:3000/login",  // local
        REDIRECT_SIGNOUT: "http://localhost:3000/login", //local
        //REDIRECT_SIGNIN: "https://d9woaq9xrwdue.cloudfront.net/login",  // desa
        //REDIRECT_SIGNOUT: "https://d9woaq9xrwdue.cloudfront.net/login", //desa
        //REDIRECT_SIGNIN: "https://d6y2tisfh85dt.cloudfront.net/login",  // test
        //REDIRECT_SIGNOUT: "https://d6y2tisfh85dt.cloudfront.net/login", //test
        //REDIRECT_SIGNIN: "https://d3rz1b1wupl5g7.cloudfront.net/login",  // prod
        //REDIRECT_SIGNOUT: "https://d3rz1b1wupl5g7.cloudfront.net/login", //prod
        RESPONSE_TYPE: "code",
        SCOPE: ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'] 
	}
};